import { useState } from "react";
import styled from "styled-components";
import BongoCat from "./Bongocat";
import Button from "components/Button/Button";
import device from "breakpoints.js";
import { ReactComponent as Work } from "components/icons/work.svg";
import { ReactComponent as Tap } from "components/icons/tap.svg";
import { isBrowser, isMobile } from "react-device-detect";

const Hero = ({ className }) => {
  const [qIsPressed, setQisPressed] = useState(false);
  const [eIsPressed, setEisPressed] = useState(false);
  const [tapLeft, setTapLeft] = useState(false);
  const [tapRight, setTapRight] = useState(false);

  const handleTouchStart = (event) => {
    if (event.touches[0].clientX < window.innerWidth / 2) {
      setTapLeft(true);
    } else {
      setTapRight(true);
    }
  };

  const handleTouchEnd = () => {
    setTapLeft(false);
    setTapRight(false);
  };

  window.addEventListener("keydown", ({ key }) => {
    if (key === "q") {
      setQisPressed(true);
    }
    if (key === "e") {
      setEisPressed(true);
    }
  });
  window.addEventListener("keyup", ({ key }) => {
    if (key === "q") {
      setQisPressed(false);
    }
    if (key === "e") {
      setEisPressed(false);
    }
  });

  return (
    <section
      className={className}
      id='hero'
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className='info'>
        <h1 className='title'>
          <span className=''>Hey!</span> I'm Dan
        </h1>
        <h2 className='subtitle'>Developer & Designer</h2>
        <div className='job'>
          <Work className='job-icon' />
          <p>
            Currently working for <a href='https://hilos.io'>Hilos</a>
          </p>
        </div>
        <a className='go-down' href='/#services'>
          <Button className='go-down-button'>&#8595;</Button>
        </a>
      </div>
      <div className='BongoCat'>
        <BongoCat
          qIsPressed={qIsPressed}
          eIsPressed={eIsPressed}
          tapLeft={tapLeft}
          tapRight={tapRight}
        />
        <div className='help'>
          {isBrowser && (
            <>
              <p className={`key ${qIsPressed && "pressed"}`}>Q</p>
              <p className=''>psssst, try pressing</p>
              <p className={`key ${eIsPressed && "pressed"}`}>E</p>
            </>
          )}
          {isMobile && (
            <>
              <Tap className='tap' />
              <p className=''>psssst, try touching your screen</p>
              <Tap className='tap' />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default styled(Hero)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  padding: clamp(1rem, 5vw, 5rem);

  .info {
    z-index: 3;
    width: clamp(20rem, 30vw, 45rem);
    order: 2;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    .title {
      font-size: clamp(2rem, 14vw, 4rem);
      font-weight: var(--font-semi);
      span {
        color: var(--primary-color);
        font-weight: var(--font-bold);
      }
    }

    .subtitle {
      font-size: 2rem;
      font-weight: var(--font-light);
      margin-bottom: 1.4rem;
    }

    .description {
      font-size: 1rem;
      font-weight: var(--font-light);
      color: #555555;
    }
    .job {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      border-radius: 0.5rem;
      color: white;
      padding: 0 1rem;
      font-weight: var(--font-semi);
      margin: 0 0 2rem 0;
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
      background-color: black;

      .job-icon {
        margin: 0;
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.4rem;
        fill: white;
      }

      .go-down {
        color: white;
        text-decoration: none;
        border-bottom: 1px solid white;
      }
    }
  }

  .BongoCat {
    z-index: 3;
    order: 1;
    padding-top: -100px;
    display: flex;
    width: clamp(20rem, 50vw, 60rem);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .help {
      display: flex;
      text-align: center;
      align-items: center;
      color: #9e9e9e;

      .tap {
        width: 3rem;
        height: 3rem;
        margin: 0 1rem;
      }

      .key {
        display: flex;
        justify-content: center;
        color: white;
        background-color: #111;
        border-radius: 10px;
        align-items: center;
        font-size: 2rem;
        width: 3rem;
        font-weight: var(--font-semi);
        margin: 0 1rem;
        padding: 5px;
        border: 1px solid #555;
        aspect-ratio: 1/1;
        transform: translateY(0.2rem);
        box-shadow: 0 5px 0 0 #828282;

        &.pressed {
          transform: translateY(5px);
          box-shadow: 0 0 0 0 #828282;
        }
      }
    }
  }

  @media ${device.tablet} {
    padding-right: 0;

    .info {
      order: 1;
      text-align: left;
      justify-content: center;
      align-items: flex-start;
    }

    .BongoCat {
      order: 2;
    }
  }
`;
