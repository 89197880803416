import styled from "styled-components";
import { ReactComponent as Code } from "components/icons/code.svg";
import { ReactComponent as Pen } from "components/icons/pen.svg";
import Liam from "components/icons/liam.gif";

const Services = ({ className }) => {
  return (
    <section id='services' className={className}>
      <div className='info'>
        <h2>What I can do for you</h2>
      </div>
      <div className='services'>
        <div className='service'>
          <div className='service-info'>
            <Code className='service-icon' />
            <h3>Web Development</h3>
            <p>Got a great website or app in mind? Let's build it</p>
          </div>
        </div>
        <div className='service'>
          <div className='service-info'>
            <Pen />
            <h3>Brand Design</h3>
            <p>A great idea needs to look awesome, Let me help you</p>
          </div>
        </div>
      </div>
      <div className='description'>
        <img src={Liam} alt='Liam Neeson Gif' />
        <div className='text'>
          <p>
            I don't know who you are. I don't know what you want. If you are
            looking for ransom, I can tell you I don't have money. But what I do
            have are a very particular set of skills, skills I have acquired
            over a very long career. <br />
            <br />
            <b>Skills that make me a perfect-fit for people like you.</b>
          </p>
        </div>
      </div>
    </section>
  );
};

export default styled(Services)`
  padding: var(--section-padding) 0;
  text-align: center;

  .description {
    width: clamp(20rem, 70vw, 45rem);
    color: #888888;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 4rem auto 0;
    padding: clamp(1rem, 5%, 2rem) 0;
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;

    img {
      width: clamp(5rem, 30%, 8rem);
      border-radius: 100%;
      aspect-ratio: 1/1;
    }

    .text {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .info {
    width: clamp(20rem, 50vw, 50rem);
    margin: 0 auto;
    margin-bottom: 3rem;

    h2 {
      font-size: 2rem;
    }
  }

  .services {
    display: flex;
    width: clamp(20rem, 90vw, 90vw);
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .service {
      display: flex;
      flex-direction: column;
      border-radius: 1rem;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: left;
      width: clamp(20rem, 40vw, 28rem);
      height: 100%;
      background-color: black;
      color: white;
      padding: 2rem;
      padding-top: 8rem;

      .service-info {
        svg {
          height: clamp(2rem, 10vw, 3rem);
          margin-bottom: 1rem;
        }
        h3 {
          color: white;
          font-size: clamp(1.5rem, 2vw, 2rem);
          margin-bottom: 0.5rem;
        }
        p {
          margin: 0;
        }
      }
    }
  }
`;
