import { useState } from "react";
import styled from "styled-components";

const BongoCat = ({ className, tapRight, tapLeft }) => {
  const [qIsPressed, setQisPressed] = useState(false);
  const [eIsPressed, setEisPressed] = useState(false);

  window.addEventListener("keydown", ({ key }) => {
    if (key === "q") {
      setQisPressed(true);
    }
    if (key === "e") {
      setEisPressed(true);
    }
  });
  window.addEventListener("keyup", ({ key }) => {
    if (key === "q") {
      setQisPressed(false);
    }
    if (key === "e") {
      setEisPressed(false);
    }
  });

  return (
    <>
      <svg
        version='1.1'
        id='Capa_1'
        x='0px'
        y='0px'
        viewBox='0 0 800 600'
        style={{ enableBackground: "new 0 0 800 600" }}
        className={className}
      >
        <g id='Gatitu'>
          <path
            class='st0'
            d='M600.7,387c0,0-7.2-37.5-35.2-78.5c0,0,25-74,13-86l-42,16c0,0-58-44-133-50l-27-41l-31,30
		c0,0-80.6,24.9-138.3,113.9L600.7,387z'
          />
          <circle cx='321' cy='261' r='9' />
          <circle cx='429' cy='297' r='9' />
          <path
            class='st1'
            d='M346.5,270c0,0,12.5,22,23.5,7c0,0,5.9,20.5,26.7,11'
          />
        </g>
        <g
          className={(eIsPressed || tapRight) && "hidden"}
          id='Patita_E_arriba'
        >
          <path class='st2' d='M465.3,353.7c0,0-12.6-128.2,66.7-44.2' />
          <ellipse class='st3' cx='495.3' cy='320.8' rx='8.8' ry='10.2' />
          <circle class='st3' cx='478' cy='315' r='6' />
          <circle class='st3' cx='488' cy='298' r='6' />
          <circle class='st3' cx='507' cy='304' r='6' />
        </g>
        <g className={(qIsPressed || tapLeft) && "hidden"} id='Patita_Q_arriba'>
          <path class='st2' d='M211.3,288.7c0,0-12.6-128.2,66.7-44.2' />
          <ellipse class='st3' cx='241.3' cy='255.8' rx='8.8' ry='10.2' />
          <circle class='st3' cx='224' cy='250' r='6' />
          <circle class='st3' cx='234' cy='233' r='6' />
          <circle class='st3' cx='253' cy='239' r='6' />
        </g>
        <line id='Mesa' class='st4' x1='90' y1='263' x2='708' y2='413' />
        <g
          className={!(eIsPressed || tapRight) && "hidden"}
          id='Patita_E_abajo'
        >
          <path class='st2' d='M518.6,363.3c0,0-115.9,44.7-62.3-35.3' />
          <line class='st5' x1='475' y1='391.9' x2='485' y2='407.7' />
          <line class='st5' x1='437.5' y1='389.5' x2='435.3' y2='403' />
          <line class='st5' x1='426.5' y1='378.5' x2='411.2' y2='382.8' />
        </g>
        <g className={!(qIsPressed || tapLeft) && "hidden"} id='Patita_Q_abajo'>
          <path class='st2' d='M285.5,306.5c0,0-120.6,49-67-31' />
          <line class='st5' x1='234' y1='339' x2='244' y2='354.8' />
          <line class='st5' x1='196.5' y1='336.6' x2='194.3' y2='350.1' />
          <line class='st5' x1='185.5' y1='325.6' x2='170.2' y2='330' />
        </g>
      </svg>
    </>
  );
};

export default styled(BongoCat)`
  width: 100%;

  .st0 {
    fill: #ffffff;
    stroke: #000000;
    stroke-width: 8;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: none;
    stroke: #000000;
    stroke-width: 5;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }

  .st2 {
    fill: #ffffff;
    stroke: #000000;
    stroke-width: 7;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st3 {
    fill: #ef9989;
    enable-background: new;
  }

  .st4 {
    fill: none;
    stroke: #000000;
    stroke-width: 7;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }

  .st5 {
    fill: none;
    stroke: #ef9989;
    stroke-width: 9;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    enable-background: new;
  }

  .hidden {
    display: none;
  }
`;
