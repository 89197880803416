import styled from "styled-components";

export default styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem ${(props) => (props.icon ? "2rem" : "1rem")};
  border: 0.5px solid black;
  border-radius: 30px;
  gap: 1rem;
  transition: all 0.3s ease-in-out;

  > svg {
    fill: black;
    transition: fill 0.3s ease-in-out;
    height: 20px;
    width: 20px;
  }

  &&:hover {
    background-color: black;
    color: white;

    > svg {
      fill: white;
    }
  }
`;
