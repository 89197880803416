import styled from "styled-components";

const Quote = ({ className, quote, author }) => {
  return (
    <div className={className}>
      <p className='quote'>{quote}</p>
      <p className='author'>{author}</p>
    </div>
  );
};

export default styled(Quote)`
  display: flex;
  padding: 0 2rem;
  text-align: center;
  padding-bottom: var(--section-padding);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .quote {
    font-size: clamp(2rem, 14vw, 4rem);
    font-weight: var(--font-bold);
    margin-bottom: 0;
    color: black;
  }

  .author {
    margin: 0;
    font-size: clamp(1rem, 14vw, 2rem);
    font-weight: var(--font-light);
    color: #555555;
  }
`;
