import Hero from "components/Hero/Hero";
import Services from "components/Services/Services";
import TechStack from "components/TechStack/TechStack";
import Quote from "components/Quote/Quote";
import Portfolio from "components/Portfolio/Portfolio";
import Team from "components/Team/Team";
import Footer from "components/Footer/Footer";

function App() {
  return (
    <>
      <Hero />
      <Services />
      <TechStack />
      <Quote quote='I ♥ JavaScript' author='– Said no one, ever...' />
      <Portfolio />
      <Team />
      <Quote author='✨ More things coming soon ✨' />
      <Footer />
    </>
  );
}

export default App;
