import styled from "styled-components";

const MemberCard = ({ className, name, role, img }) => {
  return (
    <div className={className}>
      <div className='img'>{img}</div>
      <div className='info'>
        <h3 className='role'>{name}</h3>
        <h4 className='name'>{role}</h4>
      </div>
    </div>
  );
};

export default styled(MemberCard)`
  border-radius: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: clamp(50px, 80vw, 250px);
  overflow: clip;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.3);

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 15vw;
    padding: 1rem;

    > img {
      width: 50%;
      border-radius: 100%;
    }
  }

  .info {
    background-color: #343434;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    padding: 2rem 0;

    .role {
      font-size: 1.5rem;
      margin: 0;
      color: white;
      font-weight: var(--font-bold);
      margin-bottom: 0.3rem;
    }
    .name {
      margin: 0;
      font-size: 1rem;
      color: white;
      font-weight: var(--font-light);
    }
  }
`;
