import styled from "styled-components";

const Icon = ({ className, children }) => {
  return <div className={`${className} `}>{children}</div>;
};

export default styled(Icon)`
  width: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  svg {
    height: 3.5rem;
    margin-bottom: 1rem;
  }
`;
