import styled from "styled-components";
import { ReactComponent as Logo } from "components/icons/logo.svg";

const Footer = ({ className }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={className}>
      <Logo />
      <div className='copyright'>
        <p>Daniel Contreras || Dantnad {currentYear}</p>
        <p>© All rights reserved</p>
      </div>
    </footer>
  );
};

export default styled(Footer)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;

  .copyright {
    > p {
      margin: 0;
    }
    text-align: center;
    margin-top: 1rem;
  }
`;
