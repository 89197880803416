import styled from "styled-components";
import ProjectCard from "components/ProjectCard/ProjectCard";
import { ReactComponent as LogoSoy } from "components/icons/soyagencia.svg";
import { ReactComponent as LogoCorazon } from "components/icons/corazon.svg";
import { ReactComponent as LogoDental } from "components/icons/dental.svg";
import { ReactComponent as LogoRageful } from "components/icons/rageful.svg";

const Portfolio = ({ className }) => {
  return (
    <section className={className}>
      <h3>I've done all this 👀</h3>
      <div className='portfolio'>
        <ProjectCard
          logo={<LogoSoy className='logo' />}
          color='#D666CC'
          category='Brand Design'
          href='https://www.behance.net/gallery/78644063/Soy-Agencia-Brand-Design'
        />
        <ProjectCard
          logo={<LogoCorazon className='logo' />}
          color='#D66662'
          category='Brand Design'
          href='https://www.behance.net/gallery/152004709/Corazon-Chilango'
        />
        <ProjectCard
          logo={<LogoDental className='logo' />}
          manualCenter='-15%'
          color='#55A8DD'
          category='Web Design'
          href='https://mydental.skin'
        />
        <ProjectCard
          logo={<LogoRageful className='logo' />}
          color='#343434'
          category='Brand & Web Design'
        />
      </div>
    </section>
  );
};

export default styled(Portfolio)`
  text-align: center;
  padding: var(--section-padding) 0;

  .portfolio {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
`;
