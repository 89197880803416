import styled from "styled-components";
import { ReactComponent as WhatsApp } from "components/icons/whatsapp.svg";
import MemberCard from "components/MemberCard/MemberCard";
import Button from "components/Button/Button";

const Team = ({ className }) => {
  return (
    <div className={className}>
      <h3 className='title'>Meet the team</h3>
      <div className='team'>
        <MemberCard
          img={
            <img
              src={process.env.PUBLIC_URL + "/assets/Dan.jpeg"}
              alt='Dan Contreras'
            />
          }
          name='Devsinger'
          role='- Dan Contreras -'
        />
        <MemberCard
          img={
            <img
              src={process.env.PUBLIC_URL + "/assets/Oliva.jpeg"}
              alt='Oliva'
            />
          }
          name='Owner'
          role='- Oliva -'
        />
      </div>
      <div className='contact'>
        <a href='https://api.whatsapp.com/send?phone=527227509096&text=Hola%20Dan,%20soy%20'>
          <Button icon>
            <WhatsApp />
            Say hi on WhatsApp
          </Button>
        </a>
      </div>
    </div>
  );
};

export default styled(Team)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--section-padding) 0;

  .team {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 3rem;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 2.2rem;
  }

  ${Button} {
    margin-top: 6rem;
    font-size: 1.3rem;
  }
`;
