import styled from "styled-components";
import { useState } from "react";

const Card = ({ className, category, logo, href }) => {
  const [isHovered, setIsHovered] = useState(false);

  const HandleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div
      className={className}
      onMouseEnter={HandleHover}
      onMouseLeave={HandleHover}
    >
      <a rel='noreferrer' href={href} target='_blank'>
        <div className='logo-container'>{logo}</div>
        <div className='info'>
          {isHovered ? (
            <h3 className='title'>{href ? "Ver más" : "Work in progress"}</h3>
          ) : (
            <h3 className='title'>{category}</h3>
          )}
        </div>
      </a>
    </div>
  );
};

export default styled(Card)`
  position: relative;
  transition: box-shadow 2s ease;
  overflow: clip;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  width: clamp(200px, 80%, 500px);
  background-color: ${(props) => props.color};
  border-radius: 30px;

  .logo-container {
    transition: opacity 0.3s ease;
    opacity: 1;
    position: relative;
    top: ${(props) => (props.manualCenter ? props.manualCenter : "-30%")};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .logo {
      height: 70%;
    }
  }

  &&:hover {
    box-shadow: 0 10px 10px 0 rgba(125, 125, 125, 0.701);
    .logo {
      opacity: 0;
    }

    .info {
      height: 100%;
    }
  }

  .info {
    transition: height 0.3s ease;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: darken;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;

    > * {
      color: white;
    }

    .title {
      font-size: clamp(1rem, 2vw, 2rem);
      font-weight: var(--font-semi);
    }
  }
`;
