import styled from "styled-components";
import Icon from "components/Icon/Icon";
import { ReactComponent as Illustrator } from "components/icons/ai.svg";
import { ReactComponent as MongoDB } from "components/icons/mongo.svg";
import { ReactComponent as Express } from "components/icons/express.svg";
import { ReactComponent as Sass } from "components/icons/sass.svg";
import { ReactComponent as ReactIcon } from "components/icons/react.svg";
import { ReactComponent as RouterIcon } from "components/icons/router.svg";
import { ReactComponent as Redux } from "components/icons/redux.svg";
import { ReactComponent as Javascript } from "components/icons/javascript.svg";
import { ReactComponent as Git } from "components/icons/git.svg";
import { ReactComponent as NodeJS } from "components/icons/node.svg";
import { ReactComponent as XD } from "components/icons/xd.svg";

const TechStack = ({ className }) => {
  return (
    <section className={className} id='tech-stack'>
      <h3>My tech stack</h3>
      <div className='tech-icons'>
        <Icon>
          <Illustrator />
          Illustrator
        </Icon>
        <Icon>
          <MongoDB />
          MongoDB
        </Icon>
        <Icon>
          <Express />
          Express
        </Icon>
        <Icon>
          <Sass fill='pink' />
          Sass
        </Icon>
        <Icon>
          <ReactIcon />
          React
        </Icon>
        <Icon>
          <RouterIcon />
          React Router
        </Icon>
        <Icon>
          <Redux />
          Redux
        </Icon>
        <Icon>
          <Javascript />
          Javascript ES6
        </Icon>
        <Icon>
          <Git />
          Git
        </Icon>
        <Icon>
          <NodeJS fill='green' />
          NodeJS
        </Icon>
        <Icon>
          <XD />
          Adobe XD
        </Icon>
      </div>
    </section>
  );
};

export default styled(TechStack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--section-padding) 0;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .tech-icons {
    padding: 0 5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
  }
`;
